$scrollbar-width: 10px;

html {
  --header-height: 78px;
  --navbar-active-color: #0084fe;
}

//react-leaflet-draw important rule
.sr-only {
  display: none;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6 !important;

  width: 100% !important;
  overflow: visible !important;
  overflow-y: scroll !important;
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }
  &::-webkit-scrollbar-track {
    background-color: #e8e8e8;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a0a4a8;
  }

  .ant-menu {
    @media screen and (min-width: 2000px) {
      font-size: 1.1rem;
    }
  }
  .ant-tabs-tab-active{
    background: #fff;
  }
  .ant-tabs-content-holder{
    background: #fff;
    padding: 12px;
    height: 100%;
    display: flex;
  }

}

.ant-form-item-explain-error {
  font-size: 11px !important;
}