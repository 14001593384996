.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-color: transparent;
  & li:nth-last-child(3) {
    margin-left: 460px;
    @media screen and (max-width: 1460px){
      margin-left: 400px;
    }
    @media screen and (max-width: 1340px){
      margin-left: 300px;
    }
  }
  a {
    color: inherit !important;
  }
}

.containerWithArtifical {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-color: transparent;
  & li:nth-last-child(4) {
    margin-left: 460px;
    @media screen and (max-width: 1460px){
      margin-left: 400px;
    }
    @media screen and (max-width: 1340px){
      margin-left: 300px;
    }
  }
  a {
    color: inherit !important;
  }
}
