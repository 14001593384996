.list {
  overflow-y: scroll;
  height: 300px;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e8e8e8;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a0a4a8;
  }
  .item {
    padding: 5px;
    border-radius: 6px;
    position: relative;
    transition: all 0.3s;
    &:not(:first-child) {
      margin-top: 5px;
    }
    &:hover {
      background-color: #dedede;
    }
    &.selected {
      background-color: #1677ff;
      color: white;
    }
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
